.display {
	max-width: 580px;
	margin: 0 auto;

	.header {
		position: fixed;
		top: 0;
		background: var(--semi-color-bg-3);
		border-bottom: 1px solid var(--semi-color-border);
		width: 100vw;
		height: 3rem;
		line-height: 3rem;
		z-index: 999;
		max-width: 580px;
	}

	.content {
		.index {
			margin-top: 3rem;

			.loading {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 80vh;
				top: 0;
				margin-top: 3rem;
			}

			.loadMore {
				margin: 1rem 0.5rem 0.5rem;
			}

			.content {
				overflow: auto;
    			max-height: 90vh;
				background-color: var(--semi-color-tertiary-light-default);
			}

			.item-list {
				display: flex;
				flex-wrap: wrap;
				background-color: white;
				border-radius: 10px;

				.item {
					width: calc((100% - 1.5rem) / 2);
					margin: 0.25rem 0.5rem;

					.cover {
						height: 150px;
						background-repeat: no-repeat;
						background-size: cover;
						-webkit-background-size: cover;
						-o-background-size: cover;
						background-position: center center;
						position: relative;

						span {
							position: absolute;
							bottom: 3px;
							right: 3px;
							padding: 2px 10px;
							background: rgba(255, 255, 255, 0.7);
							border-radius: 100000rem;
							transition: opacity 0.3s ease-in-out;
							font-size: 0.625rem;
							border: 1px solid var(--semi-color-border);
						}
					}

					&:nth-of-type(2n) {
						margin-left: 0.25rem;
					}

					&:nth-of-type(2n-1) {
						margin-right: 0.25rem;
					}

					&:nth-of-type(1), &:nth-of-type(2) {
						margin-top: 0.5rem;
					}

					h4 {
						font-size: 1rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.meta {
						margin-top: 10px;

						.price {
							font-size: 1rem;
							color: rgba(var(--semi-grey-6), 1);
							opacity: 0.5;
						}

						.kind {
							display: inherit;
							font-size: 0.8rem;
							color: var(--semi-color-text-secondary);
							opacity: 0.8;
							font-style: italic;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}

			.side-menu {
				overflow: auto;
    			height: 90vh;
				background-color: var(--semi-color-tertiary-light-default);

				.menu-item {
					text-align: center;
					height: 48px;
					line-height: 48px;

					font-size: 14px;

					p {
						margin: 0;
					}

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	.footer {
		padding: 3rem 0.5rem 0.5rem;
		background-color: var(--semi-color-tertiary-light-default);
		top: -1px;
		position: relative;

		p {
			text-align: right;
			opacity: 0.6;
			font-weight: 600;
			font-size: 0.875rem;
		}
	}
}

body {
	.detail {
		height: 77vh;
		overflow-y: auto;
		overflow-x: hidden;

		.cover {
			position: relative;
			border-radius: 5px;
			box-shadow: var(--semi-shadow-elevated);
			width: 300px;
			margin: 0.5rem auto;
			overflow: hidden;
			transition: all 0.3s ease-in-out;
			background-repeat: no-repeat;
			background-size: cover;
			-webkit-background-size: cover;
			-o-background-size: cover;
			background-position: center center;
			position: relative;

			.num {
				position: absolute;
				bottom: 3px;
				right: 3px;
				padding: 2px 10px;
				background: rgba(255, 255, 255, 0.7);
				border-radius: 100000rem;
				transition: opacity 0.3s ease-in-out;
				font-size: 0.625rem;
				border: 1px solid var(--semi-color-border);
			}

			.zoom {
				position: absolute;
				bottom: 3px;
				left: 3px;
				padding: 2px 10px;
				background: rgba(var(--semi-grey-2), 1);
				border-radius: 100000rem;
				transition: opacity 0.3s ease-in-out;
				font-size: 0.625rem;
				border: 1px solid var(--semi-color-border);
				color: white;
			}
		}

		h4 {
			font-size: 1.5rem;
			margin: 0.5rem 0;
			font-weight: 400;
			line-height: 1.5;
			letter-spacing: 0.03rem;
		}

		.meta {
			margin-top: 10px;

			.price {
				font-size: 1rem;
				color: var(--semi-color-primary);
			}

			.kind {
				font-size: 0.8rem;
				color: var(--semi-color-text-secondary);
				opacity: 0.3;
				font-weight: 600;
				font-style: italic;
			}
		}
	}
}
