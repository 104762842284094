.admin {
	.header {
		position: fixed;
		top: 0;
		background: var(--semi-color-bg-3);
		border-bottom: 1px solid var(--semi-color-border);
		width: 100vw;
		height: 3rem;
		line-height: 3rem;
		z-index: 999;

		.title a {
			font-size: 1.125rem;
			color: var(--semi-color-primary);
			text-decoration: none;
		}

		.header-content {
			margin: 0 5rem;
		}

		.return {
			font-weight: 600;
			text-decoration: none;
			color: var(--semi-color-primary);
			font-size: 0.875rem;
			cursor: pointer;
			margin-left: 10px;
		}
	}

	.footer {
		p {
			text-align: right;
			opacity: 0.6;
			font-weight: 600;
			font-size: 0.875rem;
		}
	}

	.login-wrapper {
		width: 100vw;
		margin: 10rem 0;
		display: flex;
		justify-content: center;
		align-items: center;

		.login-box {
			h4 {
				margin-bottom: 0.5rem;
			}

			.input {
				margin-bottom: 1rem;
			}
		}
	}

	.dashboard {
		margin: 5rem auto;
		width: calc(100vw - 10rem);
		max-width: 1440px;

		.head {
			h3 {
				margin-bottom: 20px;
			}

			.right {
				text-align: right;
			}

			.search {
				width: 200px;
				margin: 1rem 0;
			}
		}

		.thing-list {
			margin-top: 20px;

			.thing {
				.img {
					width: 32px;
					height: 32px;
					margin-right: 10px;
				}

				p {
					width: calc(100% - 50px);
					margin: 0;
					float: right;
					line-height: 32px;
				}
			}
		}
	}
}

@media (max-width: 1440px) {
	.admin {
		.header {
			width: 100vw;

			.header-content {
				margin: 0 0.5rem;
			}
		}

		.dashboard {
			width: calc(100vw - 2rem);
			.thing-list {
				.thing {
					.img {
						width: 0px;
						height: 0px;
						display: none;
					}

          p {
            float: left;
          }
				}
			}
		}
	}
}
